:root {
    --bg-color: rgba(203, 203, 204, 0.55);
    --text-color: #100f0f;
    --form-input: #34373B;
    --button: rgb(117, 235, 187);
}

html {
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body {
    background: var(--bg-color);
    color: var(--text-color);
    font-family: 'Maven Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
}

p {
    font-size: 20px;
    /* font-weight: 200; */
}

a {
    transition: 0.2s;
    text-decoration: none;
    color: #000000;
}

a:hover {
    transition: 0.2s;
    text-decoration: none;
    color: #000000;
}

*::-webkit-scrollbar {
    display: none;
}

.message-div {
    margin: 0 auto;
    padding: 20px 45px;
    background: #FFFFFF;
    border-radius: 25px;
    margin-top: 100px;
    max-width: 800px;
}

.not-viewed {
    background: #f8cb04;
}

.message-div .title {
    font-size: 32px;
    line-height: 25px;
}

.message-div .desc {
    font-size: 20px;
    line-height: 23px;
    margin-left: 10px;
}

.message-div .footer-div {
    display: flex;
    justify-content: flex-start;
}

.message-div .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.message-div .footer-text {
    margin-top: 4px;
    margin-left: 8px;
}


.message-main .main-container {
    margin: 0 auto;
    padding: 20px 45px;
    background: #FFFFFF;
    border-radius: 25px;
    margin-top: 100px;
    max-width: 800px;
}

.message-main .title {
    font-size: 32px;
    line-height: 25px;
    text-align: center;
}

.message-main .desc {
    font-size: 20px;
    line-height: 23px;
    text-align: center;
}

.message-main .avatar {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.message-main .main-text {
    font-size: 24px;
    text-align: center;
}

.image-container {
    margin: 0 auto;
    margin-top: 30px;
    left: 50%;
    border: 10px solid #FFFFFF;
    background-color: #FFFFFF;
    width: 75%;
}

.image {
    width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.back-div {
    background: #ff1717c7;
    border-radius: 80px;
    width: 100px;
    float: left;
    margin-left: -30px;
    height: 50%;
    position:-webkit-sticky; 
    position:sticky; 
    top:40%;
}

.back-arrow {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.auth {
    position: relative;
}

.auth .form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    padding: 50px;
    overflow: hidden;
    border-radius: 20px;
    text-align: center;
}

.auth form input {
    padding: 10px;
    background-color: var(--form-input);
    border: none;
    margin: 10px 0;
    border-radius: 10px;
    color: #fff;
    width: 350px;
}

.auth form input[type=submit] {
    color: black;
    background-color: var(--button);
}

.warning {
    background-color: #ED4245;
    border-radius: 10px;
    margin: 10px 0;
    color: #fff;
    padding: 10px;
}

.warning-yellow {
    background-color: #FEE75C;
    color: black;
}

.warning-green {
    background-color: #57F287;
    color: black;
}

.fetch-failed {
    text-align: center;
}

.fetch-failed .icon {
    color: var(--dc-color-red);
    font-size: 6rem;
    margin-bottom: 15px;
}

.upload h1 {
    font-size: 32px;
    margin-top: 25px;
    text-align: center;
}

.upload {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 45px;
    background: #FFFFFF;
    border-radius: 25px;
    margin-top: 100px;
    max-width: 800px;
    text-align: center;
}

.messages-sent {
    margin: 0 auto;
    padding: 20px 45px;
    background: #FFFFFF;
    border-radius: 25px;
    margin-top: 100px;
    max-width: 800px;
}

.message-div .admin {
    text-align: center;
    background: #000000;
}

.delete-container {
    margin-left: auto;
    height: 30px;
    width: 30px;
    transform: translate(-50%, -50%);
    border-radius: 40px;
}

.open-div {
    background: #17ff17c7;
    border-radius: 40px;
    width: 125px;
    float: right;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.open-button {
    padding: 0;
    margin: 0;
    font-size: 30px;
    font-weight: bold;
}

.print-btn {
    background: #000000;
    color:rgb(117, 235, 187);
    border-radius: 10px;
    width: 300px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    margin: 30px;
}

.center-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}